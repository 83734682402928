<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Dispatched international certificates</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Dispatched international certificates
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">

                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!--            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Category Name"
                      v-model="search.name"
                      outlined
                      dense
                      v-on:keyup.enter="searchShipmentCertificateRequest()"
                      @input="search.name = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn @click.prevent="searchShipmentCertificateRequest()"
                      class="btn btn-primary text-white"
                      :isLoading="isLoading">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>-->
            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Enrolment key</th>
                  <th class="px-3">Exam name</th>
                  <th class="px-3">Requested date</th>
                  <th class="px-3">Address</th>
                  <th class="px-3">Shipping method</th>
                  <th class="px-3">Status</th>
                  <th class="px-3">Options</th>
                </tr>
                </thead>
                <template>
                  <tr v-if="shipment_certificate_request.length > 0" v-for="item in shipment_certificate_request">
                    <td class="px-2">
                      <a  class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.exam_key }}
                      </a>
                    </td>
                    <td class="px-2">
                      <a  class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.product_name }}
                      </a>
                    </td>
                    <td class="px-2">
                      {{item.order_date}}
                    </td>
                    <td class="px-2">
                      <p  class=" font-weight-bolder  mb-1" v-html="item.full_address_format">
                      </p>
                    </td>
                    <td class="px-2">
                      <a  class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.shipment_method_text }}
                      </a>
                    </td>
                    <td class="px-2">
                          <span class="badge badge-warning">
                           {{item.physical_certificate_status }}
                          </span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">

                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="markDelivered(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-check</v-icon>
                                </span>
                                <span class="navi-text">Mark Delivered</span>
                              </a>
                            </b-dropdown-text>

                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="shipment_certificate_request.length == 0">
                    <td colspan="9" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
              </table>
              <b-pagination
                  v-if="shipment_certificate_request.length > 0"
                  class="pull-right mt-7"
                  @input="getAllShipmentCertificateRequest"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <v-overlay :value="delivery_overlay">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <add-tracking ref="assign-tracking" @refresh="getAllShipmentCertificateRequest"></add-tracking>
    </div>
  </v-app>
</template>
<script>
import ShipmentCertificateRequestService from "@/services/shipment-certificate-request/ShipmentCertificateRequestService";
import AddTracking from "./widget/AddTracking";

const shipmentCertificateRequest=new ShipmentCertificateRequestService();
export default {
  components: {
    AddTracking,
  },
  data(){
    return{
      isLoading:false,
      delivery_overlay:false,
      search:{
        type:'dispatched',
      },
      shipment_certificate_request:[],
      total: null,
      perPage: null,
      page: null,
    }
  },
  methods:{
    searchShipmentCertificateRequest(){
      this.getAllShipmentCertificateRequest();
    },
    getAllShipmentCertificateRequest(){
      shipmentCertificateRequest
          .paginate(this.search,this.page)
          .then(response => {
            this.shipment_certificate_request=response.data.data;
          })
          .catch((err) => {

          }).finally(() => {
        this.isLoading = false;
      });
    },
    assignTrackingNumber(item){
      this.$refs['assign-tracking'].addTrackingNumber(item);
    },
    markDelivered(itemId){
      this.$confirm({
        message: `Are you sure you would like to mark this Certificate as Delivered? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.delivery_overlay=true;
            shipmentCertificateRequest
                .markDelivered(itemId,null)
                .then((response) => {
                  this.$snotify.success("Shipping has been set to completed\n");
                  this.getAllShipmentCertificateRequest();
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                }).finally(() => {
              this.delivery_overlay = false;
            });
          }
        },
      });
    },
  },
  mounted() {
    this.getAllShipmentCertificateRequest();
  }
}
</script>